import { useNavigate } from "react-router-dom";
import { TournamentCardProps } from "./types";
import { useQueryClient } from "@tanstack/react-query";
import { getTournamentFormat } from "../../utils/tournamentsFormat";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import "./tournamentCard.less";

const TournamentCard = ({ tournament }: TournamentCardProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleTournament = () => {
    queryClient.setQueryData(["tournaments", tournament.id], tournament);
    navigate(`/torneos/${tournament.id}`);
  };

  return (
    <div className="tournament-card" onClick={handleTournament}>
      <div className="tournament-card-image">
        <img src={tournament.image} alt={tournament.name} />
      </div>
      <div className="tournament-card-content">
        <div className="tournament-card-description">
          <h6 className="tournament-card-subtitle">{getTournamentFormat(tournament)}</h6>
          <h2 className="tournament-card-title">{tournament.name}</h2>
        </div>
        <div className="tournament-card-icon">
          <ArrowRightLineIcon />
        </div>
      </div>
    </div>
  );
};

export default TournamentCard;
