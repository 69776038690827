import { useQuery } from "@tanstack/react-query";
import { getTournaments } from "../services/tournament";
import { CACHE_TIME_1_HOUR } from "../utils/constants";

export const useTournaments = () => {
  const { data, isLoading, isFetching } = useQuery(["tournaments"], getTournaments, {
    staleTime: CACHE_TIME_1_HOUR,
  });

  return {
    isFetching,
    isLoading,
    tournaments: data,
    activeTournaments: data?.filter((t) => t.status === "OG"),
    completedTournaments: data?.filter((t) => t.status === "F"),
  };
};
