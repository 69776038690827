import { Journey } from "../interfaces/journey";
import { CupTournament, LeaderboardTeam } from "../interfaces/leaderboard";
import { Showdown } from "../interfaces/showdown";
import { Tournament } from "../interfaces/tournament";
import { delay } from "../utils/delay";
import { api } from "./api";

export const getTournaments = async () => {
  const { data } = await api.get<Tournament[]>(`/api/v1/tournaments/`);
  return data;
};

export const getTournament = async (id: number) => {
  const { data } = await api.get<Tournament>(`/api/v1/tournaments/${id}/`);
  return data;
};

export const getTournamentJourneys = async (tournamentId: number) => {
  const { data } = await api.get<Journey[]>(`/api/v1/tournaments/${tournamentId}/journeys/`);
  return data;
};

export const getTournamentLeaderboard = async (id: number) => {
  const url = `/api/v1/tournaments/${id}/table/`;
  const request = () => api.get<LeaderboardTeam[]>(url);
  const [response] = await Promise.all([request(), delay(1)]);
  return response.data;
};

export const getCupBrackets = async (id: number) => {
  const url = `/api/v1/tournaments/${id}/cup_brackets/`;
  const request = () => api.get<CupTournament>(url);
  const [response] = await Promise.all([request(), delay(1)]);
  return response.data;
};


export const getTournamentJourneyShowdowns = async (tournamentId: number, journeyId: number) => {
  const url = `/api/v1/tournaments/${tournamentId}/journeys/matchs?journey_id=${journeyId}`;
  const request = () => api.get<Showdown[]>(url);
  const [response] = await Promise.all([request(), delay(1)]);
  return response.data;
};
