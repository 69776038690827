import { Button } from "rsuite";
import "./front.less";
import { scrollTo } from "../../../../utils/scrollTo";
import { CONTACT_FORM_ID } from "../../../../utils/constants";

const Front = () => {
  return (
    <div className="front-container">
      <div className="front-overlay" />
      <div className="front-wrapper">
        <div className="front-content">
          <div className="front-card">
            <h6 className="front-content-tag">BUENOS AIRES</h6>
            <h1 className="front-content-title">SQUASH INTERCLUBES</h1>
            <Button
              appearance="primary"
              type="submit"
              className="contact-button"
              onClick={() => scrollTo(CONTACT_FORM_ID)}
            >
              Inscribirse
            </Button>
          </div>
        </div>
      </div>
      <div className="indicator-container">
        <div className="indicator"></div>
      </div>
    </div>
  );
};

export default Front;
