export const scrollTo = (id: string) => {
  const container = document.getElementById(id);
  if (container) {
    const rect = container.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const scrollY = window.scrollY;
    const top = 24 + rect.top + scrollY - (viewportHeight - rect.height) / 2;
    window.scroll({
      top,
      left: 0,
      behavior: "smooth",
    });
  }
};
