import React, { useState } from "react";
import { Form, ButtonToolbar, Button, Input, Grid, Row, Col, Message, useToaster } from "rsuite";
import { CONTACT_FORM_ID } from "../../utils/constants";
import "./contact.less";
import { useFormik } from "formik";
import { ContactFormType } from "./types";
import { schema } from "./schema";
import { sendContactEmail } from "../../services/contact";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const toaster = useToaster();

  const { values, isValid, setFieldValue, handleSubmit } = useFormik<ContactFormType>({
    initialValues: {
      name: "",
      message: "",
      phone: "",
      email: "",
    },
    initialErrors: {
      name: "",
    },
    validationSchema: schema,
    onSubmit: async (values) => {
      let message = "El mensaje se envió correctamente, nos pondremos en contacto a la brevedad.";
      let type: "success" | "error" = "success";
      try {
        setLoading(true);
        await sendContactEmail(values);
      } catch (err) {
        type = "error";
        message = "Ocurrió un error al enviar el mensaje, vuelva a intentarlo en unos minutos.";
      } finally {
        setLoading(false);
        toaster.push(
          <Message showIcon type={type}>
            {message}
          </Message>,
          { placement: "bottomCenter", duration: 5000 }
        );
      }
    },
  });

  return (
    <>
      <div className="contact-container" id={CONTACT_FORM_ID}>
        <form className="contact-content" onSubmit={handleSubmit}>
          <h1 className="contact-content-title">Contactanos</h1>
          <h4 className="contact-content-subtitle">
            Si estás interesado en participar, no dudes en consultar
          </h4>
          <Grid fluid>
            <Row>
              <Col xs={24} md={12}>
                <Form.Group controlId="name">
                  <Form.ControlLabel>Nombre completo *</Form.ControlLabel>
                  <Input
                    name="name"
                    value={values.name}
                    onChange={(value) => setFieldValue("name", value)}
                  />
                  {/* <Form.HelpText>Requerido</Form.HelpText> */}
                </Form.Group>
              </Col>
              <Col xs={24} md={12}>
                <Form.Group controlId="phone">
                  <Form.ControlLabel>Teléfono *</Form.ControlLabel>
                  <Input
                    name="phone"
                    value={values.phone}
                    onChange={(value) => setFieldValue("phone", value)}
                  />
                  {/* <Form.HelpText>Requerido</Form.HelpText> */}
                </Form.Group>
              </Col>
              <Col xs={24}>
                <Form.Group controlId="email">
                  <Form.ControlLabel>Email *</Form.ControlLabel>
                  <Input
                    name="email"
                    type="email"
                    value={values.email}
                    onChange={(value) => setFieldValue("email", value)}
                  />
                  <Form.HelpText>Ingresar un formato válido</Form.HelpText>
                </Form.Group>
              </Col>
              <Col xs={24}>
                <Form.Group controlId="message">
                  <Form.ControlLabel>Mensaje *</Form.ControlLabel>
                  <Input
                    as="textarea"
                    rows={5}
                    name="message"
                    value={values.message}
                    onChange={(value) => setFieldValue("message", value)}
                  />
                  {/* <Form.HelpText>Requerido</Form.HelpText> */}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Form.Group>
                  <ButtonToolbar className="contact-button-container">
                    <Button
                      appearance="primary"
                      type="submit"
                      disabled={!isValid || loading}
                      className="contact-button"
                      loading={loading}
                    >
                      Enviar
                    </Button>
                  </ButtonToolbar>
                </Form.Group>
              </Col>
            </Row>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default Contact;
